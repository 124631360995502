<template>
    <div class="container-fluid">
		<div class="row">
			<form v-if="!loading && form" @submit.prevent="() => false" class="col-12">
				<div class="form-group">
					<label>{{ $t('notes.title') }} *</label>

					<b-form-input
						:placeholder="$t('notes.title_placeholder')"
						v-model="form.title"
					/>
				</div>

				<div class="form-group" >
					<label>{{ $t('global.date') }} *</label>
					<e-datepicker v-model="form.date" mode="date"></e-datepicker>
				</div>

				<div class="form-group">
					<label>{{ $t('notes.commentaire') }}</label>

					<e-editor-froala
						identifier="note_comment"
						:placeholder="$t('notes.commentaire')"
						:content="form.comment"
						@change="updateEditorComment"
					></e-editor-froala>
				</div>

				<div class="form-group">
					<label>{{ $t('notes.attachment') }}</label>

					<div v-for="(file, idx) in form.files" :key="file.files_id" class="row mb-2">
						<e-select
							class="col-2"
							v-model="form.files[idx].type"
							:options="file_types"
						>
                            <template slot="singleLabel" slot-scope="{ option }">{{ $t(`fichier.type.${option}`) }}</template>
							<template slot="option" slot-scope="{ option }">{{ $t(`fichier.type.${option}`) }}</template>
						</e-select>

						<b-form-file
							v-show="file.type == 'file'"
							class="col-6"
							v-model="form.files[idx].content"
							:state="hasLinkedFile && isValidSizeFileMultiple"
							:placeholder="file.files_original_name || fileInputPlaceholder"
							:drop-placeholder="file.files_original_name || $t('fichier.drop_file')+'...'"
							@change="updatedLinkedFile(idx)"
							:disabled="file.files_id > 0"
							:browse-text="getTrad('model.placeholder.logo_button_text')"
						></b-form-file>

						<b-form-input
							v-show="file.type == 'embed'"
							class="col-6"
							:placeholder="file.files_url || embedInputPlaceHolder"
							:allow-empty="false"
							v-model="form.files[idx].files_url"
							@change="updatedLinkedFile(idx)"
							:disabled="file.files_id > 0"
						/>

						<span class="col-2">
							<b-button @click="addFile">
								<font-awesome-icon :icon="['fal', 'plus-circle']" />
							</b-button>
							<b-button v-if="idx > 0 || (isEditing && file.files_id > 0)" class="ml-2" @click="rmFile(idx)">
								<font-awesome-icon :icon="['fal', 'trash']" />
							</b-button>
						</span>
					</div>
				</div>

				<div class="form-group">
					<label>{{ $t('notes.many_attachment') }}</label>

					<div class="d-block">
						<b-form-file
							class="col-6"
							v-model="multiple_files"
							:placeholder="$t('notes.select_files')"
							:drop-placeholder="$t('fichier.drop_file')+'...'"
							:browse-text="getTrad('model.placeholder.logo_button_text')"
							:multiple="true"
							@input="uploadMultiple"
						></b-form-file>
					</div>
				</div>


				<div class="form-group" v-if="!hasAlreadyAct && tiers_id == 0">
					<label>{{ $t('notes.linked_act') }}</label>

					<e-select
						v-model="form.act"
						id="actes_id"
						track-by="actes_id"
						label="inline_content"
						:placeholder="$t('notes.select_optionnal_act')"
						:selectedLabel="$t('global.selected_label')"
						:options="acts"
						:searchable="true"
						:allow-empty="true"
						:loading="loading_acts"
						:sortable="false"
					/>
				</div>

				<div class="form-group" v-if="groups.length > 0">
					<label>{{ $t("note.autorisation_groups") }}</label>

					<e-select
						v-model="form.groups"
						id="group_id"
						:placeholder="$t('note.placeholder.autorisation_groups')"
						track-by="group_id"
						label="group_label"
						:options="groups"
						:searchable="true"
						:allow-empty="true"
						:loading="loading_groups"
						:multiple="true"
					/>
				</div>

				<div class="form-group" v-if="categories.length > 0">
					<label>{{ $t("menu.setting.categories") }}</label>

					<e-select
						v-model="form.categories"
						id="categorienote_id"
						track-by="categorienote_id"
						label="categorienote_libelle"
						:options="categories"
						:searchable="true"
						:allow-empty="true"
						:multiple="true"
					/>
				</div>

				<div class="row mt-2" v-if="hasDedicatedClientArea">
					<div class="col-12">
						<b-form-checkbox id="note_display_ca" v-model="form.display_ca"><label for="note_display_ca">{{ $t("table.tiers_horse_horse.horse.set_ca_displayable") }}</label></b-form-checkbox>
					</div>
				</div>

				<div class="text-center">
					<button @click="checkForm" class="btn btn-primary rounded-pill" :disabled="working">
						<font-awesome-icon :icon="buttonFont" :pulse="working" /> {{ buttonText }}
					</button>
				</div>

				<div v-if="isBelowMd" class="mt-5"><br></div>
			</form>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Common from '@/assets/js/common.js'
	import _defaults from 'lodash/defaults'
	import _cloneDeep from 'lodash/cloneDeep'
	import _differenceBy from 'lodash/differenceBy'
    import Actes from '@/mixins/Actes.js'
    import FileMixin from '@/mixins/File.js'
    import Notes from '@/mixins/Notes.js'
    import Shutter from '@/mixins/Shutter.js'
    import ShutterNotes from '@/mixins/shutters-manager/Notes.js'
	import User from '@/mixins/User.js'
	import Window from '@/mixins/Window.js'

	export default {
        name: "WriteNote",
        mixins: [Actes, FileMixin, Notes, Shutter, ShutterNotes, User, Window],
		props: {
			acte_id: { type: Number, default: 0 },
			horse_id: { type: Number, default: 0 },
			note_id: { type: Number, default: 0 },
			tiers_id: { type: Number, default: 0 }
		},
		data () {
			return {
                default_form: {
					title: '',
					date: new Date(),
					comment: '',
					files: [],
					filename: '',
					act: null,
					display_ca: true,
					groups: [],
					categories: []
				},
				form: null,
				acts: [],
				file_types: ['file', 'embed'],
				loading: true,
				loading_acts: false,
				loading_groups: false,
				working: false,
				deleted_files: [],
                document_limit_size: '10', // en Mo
				groups: [],
				multiple_files: [],
				categories: []
			}
        },
        created() {
			this.preinit_component()
        },
        methods: {
			async preinit_component() {
				this.loading = true
				this.loading_acts = true

				if(this.horse_id) {
					await this.getLastHorseActs(this.horse_id)
						.then(res => this.acts = res)
						.finally(() => this.loading_acts = false)
				}

				this.form = _cloneDeep(this.default_form)

				let user = await this.getUserAccessRights();
				if(user.indexOf("LICADM") != -1) {
					this.loading_groups = true
					this.groups = await this.getAllGroupsNames()
					this.loading_groups = false
				}

				this.categories = await this.getAllCategorieNote()

				if(this.isEditing) {
					this.getNote(this.note_id)
						.then(res => {
							this.form.title = res.note_title
							this.form.date  = new Date(res.note_date)
							this.form.comment = res.note_comment
							this.form.display_ca = res.note_display_ca

							if(res.acte) this.form.act = this.acts.find(a => a.actes_id == res.acte.actes_id)
							if(res.files.length === 0) this.addFile()
							this.form.groups = _differenceBy(this.groups, res.groups, 'group_id')

							res.files.forEach(file => {
								this.form.files.push({
									files_id: file.files_id,
									files_original_name: file.files_original_name,
									files_url: file.files_mimetype == 'embedded' ? file.files_url : '',
									content: new File([], file.files_original_name),
									type: file.files_mimetype == 'embedded' ? this.file_types[1] : this.file_types[0],
									updated: false
								})
							})

							this.form.categories = res.categories.map(cat => this.categories.find(c => c.categorienote_id == cat.categorienote_id))
						})
						.finally(() => this.loading = false)
				}
				else {
					this.addFile()
					this.form.groups = this.groups
					this.loading = false
				}
			},

			updateEditorComment(hook) {
				this.form.comment = hook.val
			},

			updatedLinkedFile(idx) {
				this.form.files[idx].updated = true
				this.deleted_files.push(this.form.files[idx])
			},

			checkForm() {
				if(this.form.title.length === 0) return

				if(this.isEditing) {
					return this.saveEdit()
				}
				return this.saveForm()
			},

			addFile() {
				this.form.files.push({
					files_id: Common.getNegativeId(),
					files_original_name: '',
					files_url: '',
					content: new File([], 'blank'),
					type: this.file_types[0],
					updated: false
				})
			},

			rmFile(idx) {
				const file = this.form.files[idx]

				// Si c'est un fichier déjà enregistré
				if(file.files_id > 0) {
					this.deleted_files.push(file)
				}

				this.form.files.splice(idx, 1)
			},

			async saveForm() {
				try {
					this.working = true
					const acte_id = this.form.act ? this.form.act.actes_id : (this.acte_id || 0)
					const categories_ids = this.form.categories.map(c => c.categorienote_id)
					const inserted = await this.saveNote(this.horse_id, this.tiers_id, acte_id, this.form.title, this.form.date.toFormattedString(), this.form.comment, this.form.display_ca, this.form.groups, categories_ids)
					let tab_promises = []
					const files = this.form.files.filter(f => f.content.size > 0 && f.content.name != 'blank' && f.type == 'file')
					const embed = this.form.files.filter(f => f.files_url != '' && f.type == 'embed')

					if(this.hasLinkedFile) {
						files.forEach(file => {
							tab_promises.push(this.uploadMediaFile(file.content, inserted.note_id, "App\\Model\\Note"))
						})
					}

					embed.forEach(file => {
						const file_url = this.formatEmbedUrl(file.files_url)
						tab_promises.push(this.uploadEmbeddMedia(file_url, inserted.note_id, "App\\Model\\Note"))
					})

					await Promise.all(tab_promises)
					this.ok(inserted.note_id)
				}
				catch(e) {
					console.log(e)
					this.failureToast('toast.info_save_failed')
				}

				this.working = false
			},

			async saveEdit() {
				try {
					this.working = true
					const acte_id = this.form.act ? this.form.act.actes_id : (this.acte_id || 0)
					const deleted_files = this.deleted_files.map(f => { return { files_id: f.files_id }})
					const categories_ids = this.form.categories.map(c => c.categorienote_id)
					const updated = await this.saveUpdatedNote(this.note_id, acte_id, this.form.title, this.form.date.toFormattedString(), this.form.comment, this.form.display_ca, this.form.groups, deleted_files, categories_ids)

					// Upload des fichiers nouveaux, ou modifiés
					let tab_promises = []
					const files = this.form.files.filter(f => (f.updated === true || f.files_id < 0) && f.type == 'file')
					const embed = this.form.files.filter(f => (f.updated === true || f.files_id < 0) && f.type == 'embed')

					if(this.hasLinkedFile) {
						files.forEach(file => {
							tab_promises.push(this.uploadMediaFile(file.content, updated.note_id, "App\\Model\\Note"))
						})
					}

					embed.forEach(file => {
						const file_url = this.formatEmbedUrl(file.files_url)
						tab_promises.push(this.uploadEmbeddMedia(file_url, updated.note_id, "App\\Model\\Note"))
					})
					
					await Promise.all(tab_promises)
					this.ok(updated.note_id)
				}
				catch(e) {
					console.log(e)
					this.failureToast('toast.info_save_failed')
				}

				this.working = false
			},

			formatEmbedUrl(url) {
				// Si on a une url youtube, sans le format embed, on formatte l'url
				if(url.indexOf('youtube.com/watch') > -1) {
					const vid_id = url.split('=')[1]
					url = `https://www.youtube.com/embed/${vid_id}`
				}

				return url
			},

			uploadMultiple() {
				// si mon premier fichier est vide alors je supprime
				if(this.form.files[0].content.size == 0) {
					this.form.files = []
				}
				// je rajoute les fichiers de l'input multiple dans la liste de fichier du formulaire
				for(let i in this.multiple_files) {
					this.form.files.push({
						files_id: Common.getNegativeId(),
						files_original_name: this.multiple_files[i].name,
						files_url: '',
						content: this.multiple_files[i],
						type: 'file',
						updated: false
					})
				}
			}
        },
		computed: {
			isEditing() {
				return this.note_id != 0
			},
			hasAlreadyAct() {
				return this.acte_id != 0
			},
			hasLinkedFile() {
				if(!this.form || this.form.files.length === 0) return true

				const hasUpatedFiles = this.form.files
					.filter(f => f.updated === true && f.content.size > 0 && f.content.name != 'blank')
					.length > 0

				const hasOnlyDefaultFile = this.form.files.length === 1
					&& this.form.files[0].content.size == 0 
					&& this.form.files[0].content.name == 'blank'

				const hasFiles = this.form.files
					.filter(f => f.content.size > 0 && f.content.name != 'blank')
					.length > 0

				if(this.isEditing && !hasUpatedFiles && !hasOnlyDefaultFile) return true
				return hasFiles
			},
			fileInputPlaceholder() {
				return this.form.filename || this.getTrad('fichier.select_file')+' :'
			},
			embedInputPlaceHolder() {
				return this.getTrad('fichier.copy_embed')+' :'
			},
			buttonText() {
				if(this.isEditing) return this.$t('global.modifier')
				return this.$t('global.ajouter')
			},
			buttonFont() {
				if(this.working) return ['far', 'spinner']
				else if(this.isEditing) return ['far', 'pen']
				return ['far', 'plus']
			},
			isValidSizeFileMultiple() {
				if(!this.form) return true

                let isOk = true
                const limit = this.document_limit_size * 1000000 
                
				this.form.files.forEach(file => {
                    const file_size = file.content.size
                    
					if(file_size >= limit) {
                        isOk = false
                    }
                })

                return isOk
            },
			hasDedicatedClientArea() {
				return this.$store.state.userAccess.hasDedicatedClientArea
			}
		}
    }
</script>
